'use client';

import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { usePathname } from 'next/navigation';
export default function HideOnScroll({ children }: { children: React.ReactNode }) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const pathname = usePathname();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <AppBar
      position='sticky'
      color='inherit'
      sx={{
        transition: 'transform 0.3s ease-in-out',
        transform:
          pathname === '/materials'
            ? 'translateY(0)'
            : visible
              ? 'translateY(0)'
              : 'translateY(-100%)',
        bgcolor: 'background.paper',
        boxShadow: 'none',
        top: 0,
      }}
    >
      {children}
    </AppBar>
  );
}
