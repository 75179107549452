import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Link from 'next/link';
import MenuList from '@mui/material/MenuList';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

interface MenuLink {
  text: string;
  href: string;
  image?: string;
}

export interface Menu {
  title: string;
  links: MenuLink[];
}

interface MainMenuProps {
  title: string;
  menus: Menu[];
  onClick?: () => void;
}

export default function MainMenu({ title, menus, onClick }: MainMenuProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(!open);
        }}
      >
        {title}

        <KeyboardArrowDown
          sx={{ ml: 'auto', transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
          fontSize='small'
        />
      </MenuItem>

      <Collapse in={open} timeout={500}>
        {menus.map(({ title, links }, index) => (
          <MenuList key={index} disablePadding sx={{ py: 0.25 }}>
            <MenuItem sx={{ fontWeight: 'bold' }}>{title}</MenuItem>
            {links.map(({ text, href }, linkIndex) => (
              <MenuItem
                key={linkIndex}
                component={Link}
                href={href}
                onClick={() => onClick?.()}
                sx={{ px: 2 }}
              >
                {text}
              </MenuItem>
            ))}
          </MenuList>
        ))}
      </Collapse>
    </>
  );
}
