'use client';

import { useCallback } from 'react';
import React from 'react';
import Button from '@mui/material/Button';
import LoggedMenu from '@/components/auth/AccountButton/LoggedMenu';
import useAuth from '@/hooks/useAuth';

export default function AccountButton() {
  const { userId, status, setOpenLogin } = useAuth();

  const handleClick = useCallback(() => {
    setOpenLogin(true);
  }, [setOpenLogin]);

  if (!!userId) return <LoggedMenu />;

  return (
    <Button
      color='inherit'
      variant='text'
      size='small'
      loading={status === 'loading'}
      onClick={handleClick}
      sx={{ borderColor: 'transparent !important' }}
    >
      Login / Signup
    </Button>
  );
}
