'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Link from 'next/link';
import useMenu from '@/hooks/useMenu';
import MainMenuMobile, { Menu } from './MainMenuMobile';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TradeButton from './TradeButton';
import List from '@mui/material/List';
import AccountButtonMobile from '@/components/auth/AccountButtonMobile';
import toggleChat from '@/utils/toggleChat';
import Divider from '@mui/material/Divider';
interface IMenu {
  links: {
    text: string;
    href: string;
    _type: string;
    menus?: Menu[];
    title?: string;
  }[];
}

export default function MobileMenu({ links }: IMenu) {
  const pathname = usePathname();
  const { handleClick, handleClose, open } = useMenu();

  useEffect(() => {
    toggleChat(!open);
  }, [open]);

  return (
    <>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          display: {
            xs: 'flex',
            md: 'none',
          },
          color: '#000',
        }}
      >
        <MenuIcon fontSize='medium' />
      </IconButton>
      <SwipeableDrawer anchor='bottom' open={open} onClose={handleClose} onOpen={handleClick}>
        <List sx={{ bgcolor: 'divider' }}>
          {links.map(({ text, href, _type, menus, title }, index) => {
            if (_type === 'link') {
              return (
                <MenuItem
                  selected={pathname.startsWith(href)}
                  onClick={handleClose}
                  component={Link}
                  href={href as string}
                  key={index}
                >
                  {text}
                </MenuItem>
              );
            }

            if (_type === 'mainMenu' && menus && title) {
              return (
                <MainMenuMobile title={title} menus={menus} key={index} onClick={handleClose} />
              );
            }

            return null;
          })}

          <TradeButton mobile onClick={handleClose} />

          <Divider />

          <AccountButtonMobile />
        </List>
      </SwipeableDrawer>
    </>
  );
}
