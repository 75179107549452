'use client';

import { useMemo } from 'react';
import { LineItem } from '@/types/general';
import LineItems from '../LineItems';
import useDialog from '@/hooks/useDialog';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import CartIcon from '@mui/icons-material/ShoppingBagOutlined';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';

export default function Cart({ items = [] }: { items: LineItem[] }) {
  const { handleClick, handleClose, open, CloseButton } = useDialog();

  const totalItems = useMemo(
    () => items.reduce((acc, item) => acc + (item.quantity ?? 0), 0),
    [items],
  );

  return (
    <>
      <Badge badgeContent={totalItems} color='primary' overlap='circular'>
        <IconButton onClick={handleClick} color='primary' size='small'>
          <CartIcon />
        </IconButton>
      </Badge>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, px: 2, py: 1.5 }}>
          <CartIcon />
          <Typography variant='h5'>{`Cart (${totalItems} items)`}</Typography>
          <CloseButton />
        </Box>

        <DialogContent dividers>
          <LineItems
            onClose={handleClose}
            items={items}
            emptyMessage='This cart has no items.'
            type='cart'
            actions={{
              emptyCart: totalItems > 0,
              moveToQuote: totalItems > 0,
              continueShopping: true,
              summary: totalItems > 0,
              proceedToCheckout: totalItems > 0,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
