'use client';

import { useCallback } from 'react';
import React from 'react';
import LoggedMenu from '@/components/auth/AccountButtonMobile/LoggedMenu';
import useAuth from '@/hooks/useAuth';
import MenuItem from '@mui/material/MenuItem';

export default function AccountButtonMobile() {
  const { userId, status, setOpenLogin } = useAuth();

  const handleClick = useCallback(() => {
    setOpenLogin(true);
  }, [setOpenLogin]);

  if (!!userId) return <LoggedMenu />;

  return (
    <MenuItem color='inherit' disabled={status === 'loading'} onClick={handleClick}>
      Login / Signup
    </MenuItem>
  );
}
