'use client';

import { usePathname } from 'next/navigation';
import MainMenu, { Menu, MenuLink } from './MainMenu';
import Link from 'next/link';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AccountButton from '@/components/auth/AccountButton';

interface IHeader {
  links: {
    text?: string;
    href?: string;
    title?: string;
    link?: MenuLink;
    links?: MenuLink[];
    menus?: Menu[];
    _type: string;
  }[];
}

export default function Links({ links }: IHeader) {
  const pathname = usePathname();

  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
        mx: 'auto',
        display: {
          xs: 'none',
          md: 'flex',
        },
      }}
    >
      {links.map(({ title, text, href, _type, menus, link }, index) => {
        const selected = pathname.startsWith(href as string);

        if (_type === 'link') {
          return (
            <Button
              key={index}
              variant='text'
              sx={{
                borderColor: selected ? 'primary.main' : 'transparent',
              }}
              size='small'
              LinkComponent={Link}
              href={href as string}
            >
              {text}
            </Button>
          );
        }

        if (!!menus?.length) {
          return <MainMenu title={title as string} link={link} menus={menus} key={index} />;
        }

        return null;
      })}

      <AccountButton />
    </Stack>
  );
}
