'use client';

import React, { useCallback, useState } from 'react';
import { DOMAIN } from '@/constants/general';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/material/Button';

export default function LeadForm() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const form = event.target as HTMLFormElement;
      form.submit();
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Stack spacing={1.5} direction='column' sx={{ maxWidth: 420, pb: 2 }}>
      <Typography variant='h5'>Keep in touch</Typography>
      <Typography variant='body2'>
        Be the first to know about season launches, exciting new products, and exclusive offers by
        joining our mailing list.
      </Typography>

      <form
        action='https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
        method='POST'
        onSubmit={handleSubmit}
      >
        <input
          type='hidden'
          name='captcha_settings'
          value='{"keyname":"V2version","fallback":"true","orgId":"00D90000000jXZH","ts":""}'
        />
        <input type='hidden' name='oid' value='00D90000000jXZH' />
        <input type='hidden' name='retURL' value={`${DOMAIN}/thanks/`} />
        <input id='lead_source' name='lead_source' type='hidden' value='Web' />

        <Stack
          direction='column'
          spacing={0.5}
          sx={{
            alignItems: 'flex-end',
          }}
        >
          <TextField
            id='email'
            name='email'
            label='Email address'
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            size='small'
            type='email'
          />

          <div>
            <LoadingButton
              type='submit'
              variant='text'
              color='primary'
              loading={loading}
              disabled={!email}
            >
              Submit
            </LoadingButton>
          </div>
        </Stack>
      </form>
    </Stack>
  );
}
