import React, { useState, useRef, useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Image from '@/components/Image';
import Link from 'next/link';
import KeyboardArrowDown from '@mui/icons-material/ArrowDropDown';

interface MenuImage {
  src: string;
  aspectRatio: string;
}

export interface MenuLink {
  text: string;
  href: string;
  image?: MenuImage;
}

export interface Menu {
  title: string;
  links: MenuLink[];
}

interface MainMenuProps {
  title: string;
  link?: MenuLink;
  menus: Menu[];
}

export default function MainMenu({ title, link, menus }: MainMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<MenuImage | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hoverZoneRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);

  const handleMouseLeave = useCallback((event: React.MouseEvent<HTMLElement>) => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (hoverZoneRef.current && !hoverZoneRef.current.contains(relatedTarget)) {
      timeoutRef.current = setTimeout(() => {
        setAnchorEl(null);
        setOpen(false);
      }, 100);
    }
  }, []);

  const handleLinkHover = useCallback((image: MenuImage | null) => {
    setCurrentImage(image);
  }, []);

  useEffect(() => {
    if (currentImage) return;

    const firstImage = menus.flatMap((menu) => menu.links).find((link) => link.image)?.image;

    setCurrentImage(firstImage || null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={hoverZoneRef}>
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Button
          variant='text'
          sx={{
            px: 0.5,
            py: 0.15,
            borderBottom: '1px solid',
            borderColor: 'transparent',
            bgcolor: open ? 'divider' : 'transparent',
          }}
          endIcon={
            <KeyboardArrowDown
              sx={{
                transition: 'transform 0.3s',
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          }
          {...(link?.href && { href: link.href, LinkComponent: Link })}
          size='small'
        >
          {title}
        </Button>

        <Popper open={open} anchorEl={anchorEl} placement='bottom-start' sx={{ zIndex: 1300 }}>
          <Paper sx={{ px: 2, py: 1 }}>
            <Stack
              direction='row'
              spacing={2}
              sx={{
                alignItems: 'flex-start',
              }}
            >
              {menus.map(({ title, links }, menuIndex) => (
                <Stack direction='column' key={menuIndex}>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      fontWeight: 'bold',
                      p: 1,
                      textTransform: 'uppercase',
                    }}
                  >
                    {title}
                  </Typography>
                  {links.map(({ text, href, image }, linkIndex) => (
                    <MenuItem
                      key={linkIndex}
                      component={Link}
                      href={href}
                      onClick={() => {
                        setOpen(false);
                      }}
                      onMouseEnter={() => image && handleLinkHover(image || null)}
                    >
                      {text}
                    </MenuItem>
                  ))}
                </Stack>
              ))}

              <Box sx={{ pt: 1.5 }}>
                <Image
                  src={currentImage?.src || ''}
                  alt={title}
                  aspectRatio={currentImage?.aspectRatio || '1/1'}
                  objectFit='contain'
                  sx={{
                    width: 240,
                  }}
                />
              </Box>
            </Stack>
          </Paper>
        </Popper>
      </Box>
    </div>
  );
}
