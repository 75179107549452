'use client';

import { usePathname } from 'next/navigation';
import Button from '@mui/material/Button';
import Link from 'next/link';
import MenuItem from '@mui/material/MenuItem';

const href = '/nomi-designer-hub';
const text = 'Trade - Sign up';

export default function TradeButton({
  mobile = false,
  onClick,
}: {
  mobile?: boolean;
  onClick?: () => void;
}) {
  const pathname = usePathname();
  const isSelected = pathname.startsWith(href);

  if (mobile)
    return (
      <MenuItem selected={isSelected} component={Link} href={href} onClick={onClick}>
        {text}
      </MenuItem>
    );

  return (
    <Button
      color='primary'
      size='small'
      variant={isSelected ? 'contained' : 'outlined'}
      className='rounded'
      href={href}
      LinkComponent={Link}
      onClick={onClick}
      sx={{
        textTransform: 'none',
        display: {
          xs: 'none',
          md: 'flex',
        },
      }}
    >
      {text}
    </Button>
  );
}
