import { useCallback, useState } from 'react';
import { usePathname } from 'next/navigation';
import { PROFILE_LINKS } from '@/constants/general';
import MenuItem from '@mui/material/MenuItem';
import Link from 'next/link';
import useAuth from '@/hooks/useAuth';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ButtonBase from '@mui/material/ButtonBase';

export default function LoggedMenu() {
  const [open, setOpen] = useState(false);
  const { handleLogout } = useAuth();
  const pathname = usePathname();

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <MenuItem onClick={handleClick} component={ButtonBase} sx={{ width: '100%' }}>
        Account
        <KeyboardArrowDown
          sx={{ ml: 'auto', transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
          fontSize='small'
        />
      </MenuItem>

      <Collapse in={open}>
        {PROFILE_LINKS.map(({ label, href }) => (
          <MenuItem
            onClick={handleClose}
            href={href}
            key={label}
            component={Link}
            selected={pathname.startsWith(href)}
            sx={{ px: 2 }}
          >
            {label}
          </MenuItem>
        ))}

        <MenuItem
          onClick={handleLogout}
          dense
          sx={{
            color: 'error.main',
            px: 2,
          }}
        >
          Logout
        </MenuItem>
      </Collapse>
    </>
  );
}
