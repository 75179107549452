import { usePathname } from 'next/navigation';
import { PROFILE_LINKS } from '@/constants/general';
import useMenu from '@/hooks/useMenu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from 'next/link';
import useAuth from '@/hooks/useAuth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function LoggedMenu() {
  const { open, anchorEl, handleClick, handleClose } = useMenu();
  const { handleLogout } = useAuth();
  const pathname = usePathname();

  return (
    <>
      <Button
        color='inherit'
        variant='outlined'
        onClick={handleClick}
        size='small'
        endIcon={<ArrowDropDownIcon />}
        sx={{
          borderColor: 'transparent !important',
        }}
      >
        Account
      </Button>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
            backdropFilter: 'none',
          },
          '& .MuiPaper-root': {
            minWidth: 120,
          },
        }}
      >
        {PROFILE_LINKS.map(({ label, href }) => (
          <MenuItem
            onClick={handleClose}
            href={href}
            key={label}
            component={Link}
            dense
            selected={pathname.startsWith(href)}
          >
            {label}
          </MenuItem>
        ))}

        <MenuItem
          onClick={handleLogout}
          dense
          sx={{
            color: 'error.main',
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
