'use client';

import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { debounce } from 'lodash';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import get from '@/lib/sanity/get';
import Image from 'next/image';
import Skeleton from '@mui/material/Skeleton';

interface SearchOption {
  title: string;
  slug: string;
  link: string;
  image: string;
  id: string;
}

export default function SearchButton() {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<SearchOption[]>([]);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpen = () => {
    setOpen(true);
    setSearchValue('');
    setOptions([]);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        if (value.trim() === '' || value.length < 3) {
          setOptions([]);
          setLoading(false);
          return;
        }

        setLoading(true);
        const data = await get({
          query: `*[_type in ["product", "collection", "project", "page"] && (lower(seo.title) match $search || lower(seo.description) match $search || lower(sku) match $search) && status == "published"] {
            "title": seo.title,
            "slug": seo.slug.current,
            "image": seo.image.asset->url,
            "id": _id,
            "link": select(
              _type == "page" => "/" + seo.slug.current,
              _type != "page" => "/" + _type + "s/" + seo.slug.current,
            )
          }`,
          params: { search: `*${value.toLowerCase()}*` },
          cache: true,
          tags: ['search', 'products', 'collections', 'projects', 'pages'],
        }).catch(() => []);

        setOptions(data);
        setLoading(false);
      }, 300),
    [],
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchValue(value);
      debouncedSearch(value);
    },
    [debouncedSearch],
  );

  const handleOptionSelect = (_: React.SyntheticEvent, option: string | SearchOption | null) => {
    if (option && typeof option !== 'string') {
      router.push(option.link);
      handleClose();
    }
  };

  return (
    <div>
      <IconButton color='inherit' onClick={handleOpen}>
        <SearchIcon />
      </IconButton>
      <Dialog
        open={open}
        fullWidth
        maxWidth='lg'
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '80vh',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            maxWidth: 600,
            mx: 2,
            '& .MuiInputBase-root': {
              color: 'white',
              fontSize: '2rem',
              borderBottom: '2px solid white',
            },
          }}
        >
          <Autocomplete
            clearIcon={null}
            options={options}
            loading={loading}
            getOptionLabel={(option: string | SearchOption) => {
              if (typeof option === 'string') return option;
              return option?.title || '';
            }}
            renderOption={(props, option) => {
              const { key, ...otherProps } = props;
              return (
                <li key={key} {...otherProps} style={{ display: 'flex', alignItems: 'center' }}>
                  {option.image && (
                    <Image
                      src={option.image}
                      alt={option.title}
                      width={50}
                      height={50}
                      style={{
                        marginRight: 15,
                        objectFit: 'cover',
                      }}
                    />
                  )}

                  {!option.image && (
                    <Skeleton variant='rectangular' width={50} height={50} sx={{ mr: 1 }} />
                  )}

                  {option.title}
                </li>
              );
            }}
            onChange={handleOptionSelect}
            inputValue={searchValue}
            onInputChange={(_, newInputValue) => {
              setSearchValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={inputRef}
                fullWidth
                variant='standard'
                placeholder='Search...'
                onChange={handleSearch}
                autoFocus
                slotProps={{
                  input: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  },
                }}
              />
            )}
            filterOptions={(x) => x}
            freeSolo
          />
        </Box>
        <IconButton
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: 'white',
          }}
          onClick={handleClose}
          size='large'
        >
          <CloseIcon fontSize='large' />
        </IconButton>
      </Dialog>
    </div>
  );
}
